import React, { useRef } from 'react';
import Image from 'src/components/Image';
import cx from 'classnames';

// helpers
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';
import { useAnimations, ANIMATION_TRIGGERS } from 'src/hooks/useAnimations';

//components
import TextBlock from 'src/components/text-block';
import { AnimatedGroup } from 'src/components/helpers';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

// data
import {
  iconColumnData,
  highlightHeading,
  highlightP,
  textBlockData,
  app1Heading,
  app1P,
  app1CTA,
  app1Link,
  app1Alt,
  securityHeading,
  securityP,
  securityCTA,
  securityLink,
  securityAlt,
  securityScreenInfo,
  securityScreenIcon,
} from 'src/store/how-it-works-for-consumers';

const AppsSection = () => {
  const iconsSection = useRef(null);
  const highlightSection = useRef(null);
  const columnHighlight = useRef(null);

  const animatedElements = [
    {
      node: iconsSection,
      activeClass: 'how-it-works__icon-column-highlight--scrolled',
      trigger: ANIMATION_TRIGGERS.whileScrolled,
      targetNode: columnHighlight,
    },
    {
      node: highlightSection,
      activeClass: 'how-it-works__icon-column-highlight--expanded',
      trigger: ANIMATION_TRIGGERS.whileScrolled,
      targetNode: columnHighlight,
    },
  ];
  useAnimations(animatedElements);

  return (
    <>
      <section
        ref={iconsSection}
        className='how-it-works__app-icons-section text-center hide-for-aeo'
      >
        <AnimatedGroup
          trigger={ANIMATION_TRIGGERS.afterAboveViewportBottom}
          testId='icon-section-animated-group'
        >
          <div className='grid-container'>
            <div className='grid-x align-justify'>
              {iconColumnData.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      className={cx(
                        'cell',
                        'small-4',
                        'medium-2',
                        'large-1',
                        animationStyles['animated-group__animation'],
                        animationStyles[
                          'animated-group__animation--duration-1400'
                        ],
                        animationStyles['animated-group__animation--delay-600'],
                        animationStyles[
                          `animated-group__animation--slide-up-${item['slide-amount']}`
                        ],
                        item['grid-classes'],
                        {
                          'how-it-works__icon-column-highlight':
                            item['highlight-column'],
                        },
                      )}
                      {...(item['highlight-column']
                        ? { ref: columnHighlight }
                        : {})}
                    >
                      <div
                        className={`
                      how-it-works__icon-column
                      how-it-works__icon-column--push-down-${item['push-down']}
                    `}
                      >
                        {item['icon'].map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className='how-it-works__app-icon-container'>
                                <Image
                                  className='how-it-works__app-icon'
                                  src={`/assets/img/logos/app-icons/app-icon-${item['slug']}.png`}
                                  alt={`${item['name']} logo`}
                                />
                                <div className='how-it-works__app-name'>
                                  {item['name']}
                                </div>
                                <div
                                  className='how-it-works__app-detail'
                                  dangerouslySetInnerHTML={{
                                    __html: item['detail'],
                                  }}
                                />
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </AnimatedGroup>
      </section>
      <section
        ref={highlightSection}
        className='how-it-works__app-highlight-section'
      >
        <div className='grid-container'>
          <div className='grid-x grid-padding-y'>
            <div className='cell small-12 large-5 large-offset-7'>
              <h6>02</h6>
              <h4 className='how-it-works__heading'>{highlightHeading}</h4>
              <p>{highlightP}</p>
            </div>
            {iconColumnData.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {item['highlight-column'] && (
                    <div className='cell small-3 small-offset-1 text-center hide-for-large'>
                      <div className='how-it-works__icon-column how-it-works__icon-column-highlight'>
                        {item['icon'].map((item, i) => {
                          return (
                            <div
                              className='how-it-works__app-icon-container how-it-works__app-icon-container--highlight-section'
                              key={`highlight-column-icon-${i}`}
                            >
                              <Image
                                className='how-it-works__app-icon how-it-works__app-icon--highlight-section'
                                src={`/assets/img/logos/app-icons/app-icon-${item['slug']}.png`}
                                alt={`${item['name']} logo`}
                              />
                              <div className='how-it-works__app-name how-it-works__app-name--highlight-section'>
                                {item['name']}
                              </div>
                              <div
                                className='how-it-works__app-detail how-it-works__app-detail--highlight-section'
                                dangerouslySetInnerHTML={{
                                  __html: item['detail'],
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
      <TextBlock {...textBlockData} />
      <AnimatedGroup
        trigger={ANIMATION_TRIGGERS.whileScrolled}
        testId='reveal-container-animated-group'
        className='how-it-works__section-reveal-container'
      >
        <section
          className={cx(
            'how-it-works__pre-security-section',
            'how-it-works__phone-section',
            animationStyles['animated-group__transition--max-height-0-lg'],
          )}
        >
          <div className='grid-container'>
            <div className='grid-x grid-padding-x grid-padding-y'>
              <div className='cell small-12 large-5 align-self-middle'>
                <h6>03</h6>
                <h4 className='how-it-works__heading'>{app1Heading}</h4>
                <p>{app1P}</p>
                <Link href={app1Link}>
                  <a>
                    <span className='feature-section-link-text'>
                      {app1CTA} <ArrowRightSingleSmall />
                    </span>
                  </a>
                </Link>
              </div>
              <div className='cell small-8 small-push-2 large-4 large-push-1 align-self-middle text-center large-text-left'>
                <div className='how-it-works__security-screen'>
                  <Image
                    id='how-it-works-pre-security-screen'
                    src='/assets/img/phones/device-app-screen-consent.png'
                    alt={app1Alt}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='how-it-works__security-section how-it-works__phone-section'>
          <AnimatedGroup
            testId='security-section-animated-group'
            trigger={ANIMATION_TRIGGERS.afterScrolled}
          >
            <div className='grid-container'>
              <div className='grid-x grid-padding-x grid-padding-y'>
                <div className='cell small-12 large-5 align-self-middle'>
                  <h6>04</h6>
                  <h4 className='how-it-works__heading'>{securityHeading}</h4>
                  <p>{securityP}</p>
                  <Link href={securityLink}>
                    <a>
                      <span className='feature-section-link-text'>
                        {securityCTA} <ArrowRightSingleSmall />
                      </span>
                    </a>
                  </Link>
                </div>
                <div className='cell small-8 small-pull-2 medium-6 medium-push-1 large-4 large-push-1 text-center align-self-middle'>
                  <div className='how-it-works__security-screen how-it-works__security-screen--skeleton'>
                    <Image
                      src='/assets/img/how-it-works/screen-security-skeleton.png'
                      alt={securityAlt}
                    />
                    {securityScreenIcon.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Image
                            src={`/assets/img/how-it-works/diagram-icon-security-${i}.png`}
                            className={cx(
                              'how-it-works__security-screen-icon',
                              `how-it-works__security-screen-icon--${i}`,
                              animationStyles['animated-group__animation'],
                              animationStyles[
                                'animated-group__animation--fade-in'
                              ],
                              animationStyles[
                                'animated-group__animation--duration-300'
                              ],
                              animationStyles[
                                `animated-group__animation--delay-${item['delay']}`
                              ],
                            )}
                            aria-hidden='true'
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className='cell small-4 small-pull-2 medium-4 medium-push-1 large-2 large-push-1 align-self-middle'>
                  {securityScreenInfo.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className='how-it-works__security-screen-info'>
                          <hr
                            className={cx(
                              'hr',
                              'hr--color-black900',
                              'how-it-works__security-screen-hr',
                              animationStyles['animated-group__transition'],
                              animationStyles[
                                'animated-group__transition--grow-width'
                              ],
                              animationStyles[
                                'animated-group__transition--duration-300'
                              ],
                              animationStyles[
                                `animated-group__transition--delay-${item['hr-delay']}`
                              ],
                            )}
                          />
                          <p
                            className={cx(
                              animationStyles['animated-group__animation'],
                              animationStyles[
                                'animated-group__animation--fade-in'
                              ],
                              animationStyles[
                                'animated-group__animation--duration-300'
                              ],
                              animationStyles[
                                `animated-group__animation--delay-${item['copy-delay']}`
                              ],
                            )}
                          >
                            {item['copy']}
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </AnimatedGroup>
        </section>
      </AnimatedGroup>
    </>
  );
};

export default AppsSection;
